import React, { PureComponent } from 'react';

class Error extends PureComponent {
  render() {
    return (
      <div className="error">
        Error
      </div>

    )

  }
}


export default Error;
